<template>
  <div>
    <full-loader v-show="loading"></full-loader>
    <v-layout warp>
      <v-flex sm12 md4>
        <v-card class="mx-auto ma-5 pull-left" max-width="95%" outlined dark>
          <v-container>
            <div
              style="height: 80vh; overflow: auto; position: relative;overflow-x: hidden;"
            >
              <div class="left-header row col-sm-12">
                <div class="col-sm-8">
                  TEAM MANAGEMENT
                </div>
                <div
                  class="col-sm-4"
                  style="display: flex; font-size: 15px; white-space: nowrap;"
                >
                  ADD TEAM
                  <v-icon
                    class="ml-4"
                    style="font-size: 25px; cursor: pointer;"
                    large
                    color="green darken-2"
                     @click="clickAdd()"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                </div>
              </div>
              <div>
                <v-row justify="center">
                  <v-form ref="leftForm" class="left-wrapper" v-model="valid">
                    <v-row>
                      <v-col cols="12" class="mt-5" style="width: 28vw;">
                        <v-autocomplete
                          label="Select Project"
                          :items="zones"
                          item-text="name"
                          @change="getProject()"
                          v-model="form.project"
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row
                      v-show="
                        selectedProject && selectedProject.getMetadata('logo')
                      "
                    >
                      <v-col cols="12">
                        <v-list-item-avatar tile size="120" style="height: 50px;"
                          ><img
                            :src="
                              selectedProject &&
                                selectedProject.getMetadata('logo')
                            "
                            alt="Team"
                        /></v-list-item-avatar> </v-col
                    ></v-row>
                    <v-row v-show="selectedProject">
                      <v-col cols="12">
                        <v-icon large class="mr-4" color="white darken-2">
                          mdi-transmission-tower </v-icon
                        >{{ selectedProject && selectedProject.name }}
                      </v-col>
                    </v-row>
                    <v-row v-show="selectedProject">
                      <v-col cols="12">
                        <v-icon large class="mr-4" color="white darken-2">
                          mdi-latitude </v-icon
                        >{{
                          selectedProject && selectedProject.getMetadata("lat")
                        }}
                      </v-col>
                    </v-row>
                    <v-row v-show="selectedProject">
                      <v-col cols="12">
                        <v-icon large class="mr-4" color="white darken-2">
                          mdi-longitude </v-icon
                        >{{
                          selectedProject && selectedProject.getMetadata("lng")
                        }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="mt-5" style="width: 28vw;">
                        <v-autocomplete
                          label="Select Team"
                          :items="teams"
                          item-text="name"
                          @change="getTeam()"
                          v-model="form.team"
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn block depressed color="success" :disabled="!this.teamId || !this.projectId" @click="updateProject()">
                          UPDATE
                        </v-btn>
                      </v-col></v-row
                    >
                  </v-form>
                </v-row>
              </div>
            </div>
          </v-container>
        </v-card>
      </v-flex>

      <v-flex sm12 md8>
        <v-container fluid grid-list-md class="right-wrapper">
          <v-layout row wrap v-if="teams">
            <v-flex v-for="team in teams" :key="team._id" sm12 md2 lg4>
              <v-card
                class="mx-auto ma-5 pull-left"
                :color="getRandomColor()"
                dark
                max-width="320"
                outlined
              >
                <v-list-item three-line>
                  <v-list-item-content>
                     <div class="overline mb-4">
                      {{team.name}}
                    </div>
                    <v-list-item-subtitle v-for="(data, key) in team.metadata" :key="key" class=" mb-1">
                      {{data.key != 'createdBy' ? data.key.toUpperCase() + ':' + '\n' : null}} {{data.key != 'createdBy' ? data.value : null}}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-avatar
                    tile
                    size="80"
                    color="grey"
                    style="border-radius: 50px !important;"
                    ><img src="@/assets/team.png" alt="Team"
                  /></v-list-item-avatar>
                </v-list-item>

                <v-card-actions>
                   <v-btn outlined rounded text @click="editTeam(team)">
                      Update
                    </v-btn>
                    <v-btn @click="deteteTeam(team._id, team.name)" rounded text>
                      Delete
                    </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="400px">
          <v-card>
            <v-card-title v-if="isAddTeam">
              Add Team
            </v-card-title>
            <v-card-title v-if="!isAddTeam">
              Edit Team
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Team Name*"
                        type="text"
                        :rules="rules.teamName"
                        v-model="form.teamName"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        label="Select Pilot*"
                        :items="users"
                        item-text="fullname"
                        v-model="form.pilot"
                        :rules="rules.pilot"
                        dense
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        label="Select Co Pilot*"
                        :items="users"
                        item-text="fullname"
                        v-model="form.co_pilot"
                        :rules="rules.co_pilot"
                        dense
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        label="Select Thermographer*"
                        :items="users"
                        item-text="fullname"
                        v-model="form.thermographer"
                        :rules="rules.thermographer"
                        dense
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="
                  dialog = false;
                  $refs.form.reset();
                "
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="!valid"
                @click.prevent="submit"
                @click="addTeam()"
              >
                <span v-if="isAddTeam">Submit</span>
                <span v-if="!isAddTeam">Update</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>

<script>
import Mixins from "@/mixin";
import AdminAPI from "@/services/admin_api";
import GenericObject from "../generic_object";
import FullLoader from "../components/full-loader.vue";
export default {
  mixins: [Mixins],
  components: {
    "full-loader": FullLoader,
  },
  data() {
    return {
      dialog: false,
      valid: true,
      users: null,
      projectId: null,
      teamId: null,
      selectedProject: null,
      selectedTeam: null,
      isAddTeam: true,
      zones: [],
      tempZones: [],
      teams: [],
      selectedTeamData: [],
      form: {
        teamName: null,
        pilot: null,
        co_pilot: null,
        thermographer: null,
        project: null,
        createdBy: null
      },
      // validation rules
      rules: {
        teamName: [this.required("Team Name")],
        pilot: [this.required("Pilot")],
        co_pilot: [this.required("Co-Pilot")],
        thermographer: [this.required("Co-Pilot")],
        project: [this.required("Project")],
      },
      loggedUser:null,
      loggedTeam:[],
       loading: false
    };
  },
  methods: {
    getRandomColor() {
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += Math.floor(Math.random() * 10);
      }
      return color;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
    },
    async getAllUsers() {
       this.loading = true;
      let usersList = await AdminAPI.get(`/users`);
      this.loading = false;
      this.users = usersList.data;
       

      // if (
      //   this.loggedUser &&
      //   (this.loggedUser.role == "Admin" ||
      //     this.loggedUser.role == "AdminClient")
      // ) {
      //   this.users = this.users.filter((x) => {
      //     if (x.createdBy && x.createdBy._id == this.loggedUser._id) {
      //       return x;
      //     }
      //   });
      // }
     
      this.users = [...this.users];
      // console.log("users--", this.users, this.loggedUser );
    },
    getProject() {
      this.selectedProject = this.zones.find(
        (x) => x["name"] == this.form.project
      );
     
      var data = this.tempZones.find(
        (x) => x["name"] == this.form.project        
      );
      this.projectId = data._id;
      if(!this.teamId) {
        if(data.metadata) {
          for (var key in data.metadata) {
            var obj = data['metadata'];
            if (obj[key]['key'] == 'team_id') {  
              // console.log("matched", obj[key])
              var teamSel = this.teams.find(
                (x) => x["_id"] == obj[key]['value']
              );
              // console.log(teamSel);
              if(teamSel) {
                this.form.team = teamSel.name;
              } else {
                this.form.team = '';
              }
            }
          }
        }
      }
      
    },
    async updateProject() {  
      this.loading = true;  
      let payload = {
        'team_id': this.teamId
      }
      const dataSubmit = await AdminAPI.put(
        `zones/${this.projectId}`,
        payload,
        ''
      );
      if(dataSubmit) {
       
        this.projectSelected = null;
        if(dataSubmit.status) {
           this.loading = false;
          this.$swal("Updated!", "Team has been updated with project.", "success");
          const objIndex = this.tempZones.findIndex((obj => obj._id == this.projectId));
          var child = this.zones[objIndex];
          var matched = false;
          for (var key in child['metadata']) {
            var obj = child['metadata'];
            if (obj[key]['key'] == 'team_id') {
              matched = true;
              obj[key]['value'] = this.teamId;
            }
          }
          if (!matched) {
            child['metadata'].push({
              'key': 'team_id',
              'value': this.teamId
            });
          }
          this.zones[objIndex]['metadata'] = child['metadata'];
          this.form.project = '';
          this.form.team = '';
          this.projectId = '';
          this.teamId = '';
          this.selectedProject = '';
        } else {
           this.loading = false;
          this.$swal("Failed!", "Kindly chekc the details", "error");

        }
      }      
    },
    async clickAdd() {
      this.dialog = true;
      this.isAddTeam = true;
    },

    async addTeam() {
      
      this.form.createdBy = this.loggedUser._id;
      if(this.isAddTeam) {
        this.dialog = false;
         this.loading = true;
        const dataSubmit = await AdminAPI.post(
            `inspection-team`,
            this.form,
            ""
        );
        if(dataSubmit) {
          if(dataSubmit.status) {
             this.loading = false;
            this.$swal("Added!", "Team has been added.", "success");
            this.teams.push(dataSubmit.data);
          } else {
             this.loading = false;
            this.$swal("Failed!", "Team not added", "error");
          }          
        }
      } else {
        this.dialog = false;
        // console.log(this.form);
         this.loading = true;
        const dataSubmit = await AdminAPI.put(
            `inspection-team/${this.selectedTeam._id}`,
            this.form,
            ""
        );
        if(dataSubmit) {
          if(dataSubmit.status) {
            this.loading = false;
            this.$swal("Updated!", "Team has been updated.", "success");          
            this.isAddTeam = true;
            const objIndex = this.teams.findIndex((obj => obj._id == this.selectedTeam._id));
            this.teams[objIndex] = dataSubmit.data;
          } else {
            this.loading = false;
            this.$swal("Failed!", "Team not added", "error");
          }          
        }
        
      }
      this.$refs.form.reset();      
    },
    async editTeam(data) {
      this.selectedTeam = data;
      this.isAddTeam = false;
      this.dialog = true;
      this.form.teamName = data.name;
      this.form.pilot = data.metadata[0]['value'];
      this.form.co_pilot = data.metadata[1]['value'];
      this.form.thermographer = data.metadata[2]['value'];
    },
    async deteteTeam(id, name) {
      this.$swal({
        title: "Delete Team",
        text: `Do you want to delete this team (${name})?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it"
      }).then(async result => {
        if (result.value) {   
          this.loading = true;
          const response = await AdminAPI.delete(`inspection-team/${id}`);
        
          if(response.status) {
             this.loading = false;
            var index = this.teams.findIndex(function(o){
              return o._id === id;
            })
            if (index !== -1) this.teams.splice(index, 1); 
            this.$swal("Deleted!", "Team has been deleted.", "success");
          } else {
             this.loading = false;
            this.$swal("Failed!", "Team not deleted.", "error");
          }       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
           this.loading = false;
          this.$swal("Cancelled", "Team not deleted :)", "error");
        }
      });
    },
    getTeam() {
    
      this.selectedTeam = this.teams.find(
        (x) => x["name"] == this.form.team
      );
      this.teamId = this.selectedTeam._id;
      // console.log(this.teamId)
    },
    async getMyAdminTeam(teamList){
      if(teamList.data && teamList.data.length > 0) {
          for (const iterator of teamList.data) {
            let currentItem = new GenericObject(iterator);

            let createdBy = currentItem.getMetadata("createdBy") ? currentItem.getMetadata("createdBy") : "";
            if(createdBy && createdBy == this.loggedUser._id){
                // console.log("admin",iterator);
                this.teams.push(iterator);
            }
          }
       } 
    },
    async getMyAClientTeam(teamList){

      if(this.loggedUser && this.loggedUser.role == "Admin"){

        if(this.users && this.users.length > 0) {
          var myCreatedUsers = this.users.filter((x) => {
            if(x.createdAdmin && x.createdAdmin.length > 0 && x.role == "AdminClient") {
              for (const adminID of x.createdAdmin) {
                if(adminID == this.loggedUser._id) {
                  return x;
                }
              }
            } else if (x.createdBy && x.createdBy._id == this.loggedUser._id && x.role == "AdminClient") {
              return x;
            }
          });  
        }

          if(teamList.data && teamList.data.length > 0) {

            for (const iterator of teamList.data) {
              let currentItem = new GenericObject(iterator);
              let createdBy = currentItem.getMetadata("createdBy") ? currentItem.getMetadata("createdBy") : "";
              if(myCreatedUsers && myCreatedUsers.length > 0) {
                for (const value of myCreatedUsers) {
                      if(createdBy && createdBy == value._id){
                        // console.log("merged AClient team ::",iterator.name)
                        this.teams.push(iterator);
                      }
                  }
              }
            }

          }             
        } //if end
    }, 
    async getMyzones(){
      if (
      this.loggedUser &&
      this.loggedUser.access_levels.zones &&
      this.loggedUser.access_levels.zones.length > 0
    ) {
      let loggedZones = this.loggedUser.access_levels.zones;
      this.loading = true;
      let zoneList = await AdminAPI.get(`/zones`);
      this.loading = false;
          let allZones = zoneList.data;
          for (const iterator of allZones) {
              for (const iterator2 of loggedZones) {
                if(iterator.name == iterator2.name){
                  let currentItem = new GenericObject(iterator);
                  this.zones.push(currentItem);
                  this.tempZones.push((iterator));
                }
              }
          }

      }
    }
  },
  // watch: {
  //   var zones = this.zones;
  // },
  async mounted() {
    this.loggedUser = JSON.parse(localStorage.getItem("userData"));
    await this.getAllUsers();

  //logged *********
   if (  this.loggedUser &&
        (this.loggedUser.role == "Admin" ||
          this.loggedUser.role == "AdminClient")
      ) {
        //logged user ->filter zone:     
        await this.getMyzones();
        let teamList = await AdminAPI.get(`inspection-team`);
        //logged user ->filter team:
        await this.getMyAdminTeam(teamList);
        //logged user -->as Admin:[adminClient team]
        await this.getMyAClientTeam(teamList);
      
      }else{  //suprAdmin
          let zoneList = await AdminAPI.get(`/zones`);
           //Zone All:
          let allZones = zoneList.data;
          for (const iterator of allZones) {
            this.zones.push(new GenericObject(iterator));
            this.tempZones.push((iterator));
          }
          //team All:
           this.loading = true;
           let teamList = await AdminAPI.get(`inspection-team`);
           this.loading = false;
            if(teamList.data && teamList.data.length > 0) {
              for (const iterator of teamList.data) {
                this.teams.push(iterator);
              }
            } 
      }
    //************************* */  
    
  },
};
</script>

<style scoped>
.right-wrapper {
  height: 85vh;
  overflow-y: auto;
}
.left-wrapper {
  width: 28vw;
  text-align: center;
}
</style>
